import React from "react";
import {
  Grid,
  Box,
  Container,
  Typography,
  List,
  ListItem,
  makeStyles,
} from "@material-ui/core";
import { AiFillMediumCircle } from "react-icons/ai";
import { AiFillTwitterCircle } from "react-icons/ai";
import { FaDiscord } from "react-icons/fa";

import { Link } from "react-router-dom";

const useStyles = makeStyles((theme) => ({
  footerSection: {
    borderTop: "1px solid #E3E3E3",
    paddingTop: theme.spacing(5),
    paddingBottom: "10px",
    [theme.breakpoints.up("sm")]: {
      paddingTop: theme.spacing(5),
    },
    [theme.breakpoints.up("md")]: {
      paddingTop: theme.spacing(5),
    },
    "& a": {
      display: "flex",
      alignItems: "center",
      textDecoration: "none",
      color: "#000",
      fontSize: "16px",
      transition: "0.3s",
      "&:hover": {
        color: "#EE1D23",
        transform: "translateX(10px)",
      },
      "& svg": {
        marginRight: "10px",
      },
    },
    "& ul": {
      padding: "0",
    },
    "& h3": {
      fontSize: "24px",
      fontWeight: "600",
      color: "#000",
      marginTop: "20px",
      marginBottom: "20px",
      [theme.breakpoints.down("sm")]: {
        fontSize: "20px",
      },
    },
  },
  borderBottmo: {
    overflow: "hidden",
    background: "#000",
    paddingTop: theme.spacing(6),
    paddingBottom: theme.spacing(6),
    [theme.breakpoints.down("md")]: {
      paddingTop: theme.spacing(3),
      paddingBottom: theme.spacing(3),
    },
  },

  largeIcon: {
    width: 18,
    height: 18,
    marginRight: "8px",
  },
  icons: {
    justify: "flex-end",
    [theme.breakpoints.down("sm")]: {
      justify: "center",
    },
  },
  button: {
    backgroundColor: "#fab758",
    color: "#000",
    border: "2px solid #fab758",
    borderRadius: "40px",
    minHeight: "50px",

    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    fontSize: "16px",
    lineHeight: "1.5",
    fontWeight: "500",
    transition: "0.26s ease",
    minWidth: "160px",
    paddingBottom: "3px",
    textTransform: "capitalize",
    marginRight: "15px",
    marginBottom: "10px",
    marginTop: "27px",
  },
  join: {
    color: "#000",
    fontSize: "30px",
  },
  textFild: {
    position: "relative",

    "& button": {
      position: "absolute",
      top: 0,
      right: 0,
      height: "100%",
      backgroundColor: "#3F78E0",
      minWidth: "90px",
      fontSize: "16px",
      fontWeight: "500",
      color: "#fff",
      "&:hover": {
        backgroundColor: "#000",
      },
    },
  },
  text: {
    color: "#000",
    marginTop: "10px",
    fontSize: "16px",
    paddingRight: "50px",
    [theme.breakpoints.down("sm")]: {
      paddingRight: "0px",
    },
  },
}));

export default function Liquidity() {
  const classes = useStyles();
  return (
    <>
      <Box className={classes.footerSection}>
        <Container maxWidth="lg">
          <Grid container style={{ color: "white" }} spacing={3}>
            <Grid item xs={12} sm={12} md={6}>
              <Box pt={0} pb={3}>
                <img
                  src="/images/spaceLogo.png"
                  alt=""
                  style={{ height: "120px" }}
                />
              </Box>
              {/*
              <Typography variant="body2" className={classes.text}>
                // Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                Feugiat // lectus aliquam platea commodo in sapien nullam elit.
                Morbi // aliquam lobortis ut faucibus facilisis netus sagittis
                quis // egestas. Ac est curabitur. //{" "}
  </Typography>*/}
            </Grid>

            <Grid item xs={6} sm={6} md={3}>
              <Typography
                className="footerTitle"
                variant="h3"
                style={{ color: "#000" }}
              >
                ABOUT US
              </Typography>
              <List className="listingFooter">
                <ListItem style={{ color: "#000" }}>
                  <a
                    // href="images/ccrowne.pdf" target="_blanck"
                    variant="body1"
                  >
                    White Paper
                  </a>
                </ListItem>

                {/* <ListItem
                
                  to="/team"
                  component={Link}
                >
                  Team
                </ListItem> 
                <ListItem to="/brackers" component={Link}>
                  Brackers
                </ListItem>*/}
                <ListItem to="/privacy-policy" component={Link}>
                  Privacy Policy
                </ListItem>
              </List>{" "}
            </Grid>

            {/* <Grid item xs={6} sm={4} md={3}>
              <Typography
                className="footerTitle"
                variant="h3"
                style={{ color: "#000" }}
              >
                LEARN MORE
              </Typography>
              <List className="listingFooter">
                <ListItem
                  to="/roadmap"
                  component={Link}
                  style={{ color: "#000" }}
                >
                  Roadmap
                </ListItem>
                <ListItem
                  to="/racefeatures"
                  component={Link}
                  style={{ color: "#000" }}
                >
                  Features
                </ListItem>
                <ListItem
                  to="/marketplace"
                  component={Link}
                  style={{ color: "#000" }}
                >
                  Marketplace
                </ListItem>
              </List>{" "}
            </Grid>
            */}
            <Grid item xs={6} sm={6} md={3}>
              <Typography
                className="footerTitle"
                variant="h3"
                style={{ color: "#000" }}
              >
                SOCIAL
              </Typography>
              <List className="listingFooter">
                <ListItem>
                  {" "}
                  <a href="https://twitter.com/" target="_blank">
                    {" "}
                    <AiFillTwitterCircle style={{ fontSize: "25px" }} /> Twitter
                  </a>{" "}
                </ListItem>
                <ListItem>
                  {" "}
                  <a href="https://discord.com/" target="_blank">
                    {" "}
                    <FaDiscord style={{ fontSize: "25px" }} /> Discord
                  </a>{" "}
                </ListItem>
                <ListItem>
                  {" "}
                  <a href="https://medium.com/log-in" target="_blank">
                    {" "}
                    <AiFillMediumCircle style={{ fontSize: "25px" }} /> Medium
                  </a>{" "}
                </ListItem>
              </List>{" "}
            </Grid>
          </Grid>
        </Container>
      </Box>
    </>
  );
}
