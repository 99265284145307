import _ from "lodash";
import { colors, createMuiTheme, responsiveFontSizes } from "@material-ui/core";
//import typography from "./typography";

const baseOptions = {
  typography: {
    subtitle2: {
      fontWeight: 400,
      fontSize: 14,
    },
  },
  overrides: {
    // MuiSvgIcon: {
    //   root: {
    //     fill: "currentColor",
    //     color: "#e03128",
    //     width: "1em",
    //     height: "1em",
    //     display: "inline-block",
    //     fontSize: "1.5rem",
    //     transition: "fill 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms",
    //     flexShrink: "0",
    //     userSelect: "none",
    //     marginRight: "7px",

    //   },

    // },
    // MuiCardContent:{
    //   root:{
    //     "&:last-child":{
    //       paddingBottom: "0",
    //     },
    //   },
    // },
    MuiTypography: {
      body2: {
        color: "#7e8299",
        fontSize: "12px",
        fontWeight: "500",
      },
      paragraph: {
        fontSize: "16px",
      },
    },
    MuiListItem: {
      gutters: {
        paddingLeft: 0,
      },
    },
    MuiLinearProgress: {
      colorPrimary: {
        backgroundColor: "#ee1d23",
      },
      root: {
        borderRadius: 3,
        overflow: "hidden",
      },
    },
    MuiButton: {
      containedSizeSmall: {
        padding: " 4px 10px",
        fontSize: "14px",
        height: "46px",
        minWidth: "129px",
      },
      containedPrimary: {
        color: "rgb(255 255 255 / 87%)",
        backgroundColor: "#e03128",
        "&:hover": {
          backgroundColor: "#000",
          boxShadow:
            " 0px 2px 4px -1px rgb(0 0 0 / 20%), 0px 4px 5px 0px rgb(0 0 0 / 14%), 0px 1px 10px 0px rgb(0 0 0 / 12%)",
          color: "#fff",
        },
      },
      containedSecondary: {
        color: "rgb(255 255 255 / 87%)",
        backgroundColor: "#000",
        "&:hover": {
          backgroundColor: "#fff",
          boxShadow:
            " 0px 2px 4px -1px rgb(0 0 0 / 20%), 0px 4px 5px 0px rgb(0 0 0 / 14%), 0px 1px 10px 0px rgb(0 0 0 / 12%)",
          color: "#000",
        },
      },
      outlinedSecondary: {
        color: " #000000",
        border: "1px solid rgb(0 0 0)",
        height: "50px",
        minWidth: "150px",
        "& svg": {
          marginRight: "10px",
        },
        "&:hover": {
          backgroundColor: "#000",
          boxShadow:
            " 0px 2px 4px -1px rgb(0 0 0 / 20%), 0px 4px 5px 0px rgb(0 0 0 / 14%), 0px 1px 10px 0px rgb(0 0 0 / 12%)",
          color: "#fff",
          border: "1px solid #000000",
        },
      },
      outlined: {
        border: "1px solid #300760",
        padding: "5px 15px",
        minWidth: "160px",
        height: "50px",
        borderRadius: "100px",
        background: "#FFFFFF",
        fontSize: "16px",
        lineHeight: "19px",
        color: "#300760",
        "@media (max-width:767px)": {
          minWidth: "150px",
          height: "50px",
        },
        "&:hover": {
          backgroundColor: "#EE1D23",
          boxShadow:
            " 0px 2px 4px -1px rgb(0 0 0 / 20%), 0px 4px 5px 0px rgb(0 0 0 / 14%), 0px 1px 10px 0px rgb(0 0 0 / 12%)",
          color: "#fff",
        },
      },
      contained: {
        border: "1px solid rgba(0, 0, 0, 0.23)",
        padding: "5px 15px",
        minWidth: "200px",
        height: "60px",
        borderRadius: "100px",
        backgroundColor: "#000",
        fontSize: "16px",
        lineHeight: "19px",
        color: "#fff",
        "&:hover": {
          backgroundColor: "#fff",
          color: "#000",
        },
      },
      outlinedPrimary: {
        borderRadius: "50px",
        color: "#300760",
        fontWeight: 600,
        padding: "5px 19px",
        border: "2px solid #300760",

        "&:hover": {
          backgroundColor: "#f30065",
          border: "2px solid #f30065",
          color: "#fff",
        },
      },
      outlinedSizeSmall: {
        padding: "6px 23px",
        fontSize: "16px",
        lineHeight: " 24px",
      },
    },

    MuiAccordionDetails: {
      root: {
        display: "block",
        background: "#fff",
      },
    },
    PrivateTabIndicator: {
      colorPrimary: {
        backgroundColor: "transparent",
      },
    },
    MuiTab: {
      root: {
        borderRadius: 0,
        border: "none",
        borderBottom: "none",
        "&$selected": {
          color: "#ffffff !important",
          backgroundColor: "#e03128",
          borderBottomColor: "#e03128",
        },
      },
      textColorPrimary: {
        color: " #010101",
        fontSize: "14px",
      },
      wrapper: {
        alignContnet: "flex-start",
      },
    },

    MuiListItemIcon: {
      root: {
        minWidth: 32,
      },
    },
    MuiChip: {
      root: {
        backgroundColor: "rgba(0,0,0,0.075)",
      },
    },

    MuiPaper: {
      transition: "transform 1.5s ease-in-out;",
      rounded: {
        borderRadius: 0,
        padding: 0,
        "&:hover": {
          backgroundColor: "#f5f5f5",
          // boxShadow: "0 0 20px rgb(98 208 157 / 19%)",
        },
      },
      outlined: {
        borderRadius: 10,
        padding: 10,
      },
      elevation2: {
        boxShadow: "0 0 20px rgb(98 208 157 / 19%)",
      },
    },

    MuiInputBase: {
      root: {
        height: " 50px",
        color: "#000000",
      },
    },
    MuiSelect: {
      outlined: {
        borderRadius: "50px",
      },
    },
    MuiInputLabel: {
      outlined: {
        transform: "translate(20px, 18px) scale(1) ",
      },
      marginDense: {
        transform: "translate(20px, 18px) scale(1) ",
        paddingTop: "6px",
      },
    },
    MuiStepContent: {
      root: {
        marginLeft: 281,
        marginTop: 0,
        paddingLeft: 250,
        paddingRight: 300,
      },
    },

    MuiStepLabel: {
      iconContainer: {
        order: 2,
        paddingLeft: 30,
      },
      labelContainer: {
        width: 226,
        textAlign: "right",
      },
    },

    MuiStepConnector: {
      vertical: {
        marginLeft: 281,
        padding: 0,
      },
      lineVertical: {
        borderLeftWidth: "1px",
      },
      "&$active": {
        borderColor: "#00e0b0",
      },
    },

    MuiStepIcon: {
      backgroundColor: "white",

      completed: {
        backgroundColor: "#edf5f3",
        border: "none",
      },
    },

    MuiOutlinedInput: {
      root: {
        borderRadius: "25px",
        "&:hover": {},
      },
      inputMarginDense: {
        paddingTop: "13.5px",
        paddingBottom: "13.5px",
      },
    },

    MuiTableHead: {
      root: {
        backgroundColor: "#000000",
        color: "white",
      },
    },

    MuiTableCell: {
      root: {
        borderBottomWidth: "1px",
        borderBottomStyle: "dashed",
        borderBottomColor: "#eff2f5",
      },
      head: {
        color: "white",
      },
    },
    MuiDialogTitle: {
      root: {
        minWidth: "300px",
      },
    },
  },
};

const themesOptions = {
  typography: {
    fontWeight: 400,
  },
  palette: {
    type: "light",
    action: {
      primary: "#20509e",
    },
    background: {
      default: "#FBFBFD",
      dark: "#f3f7f9",
      paper: colors.common.white,
    },
    primary: {
      main: "#300760",
      dark: "#202831",
      light: "#e2e3fa",
    },
    secondary: {
      main: "#00e0b0",
    },
    warning: {
      main: "#ffae33",
      dark: "#ffae33",
      light: "#fff1dc",
    },
    success: {
      main: "#54e18c",
      dark: "#54e18c",
      light: "#e2faec",
    },
    error: {
      main: "#ff7d68",
      dark: "#ff7d68",
      light: "#ffe9e6",
    },
    text: {
      primary: "#52565c",
      secondary: "#999999",
    },
    common: {
      black: "#222222",
    },
  },
};

export const createTheme = (config = {}) => {
  let theme = createMuiTheme(_.merge({}, baseOptions, themesOptions));

  if (config.responsiveFontSizes) {
    theme = responsiveFontSizes(theme);
  }

  return theme;
};
