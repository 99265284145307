import React, { lazy } from "react";
import { Redirect } from "react-router-dom";
// import DashboardLayout from "src/layouts/DashboardLayout";
import HomeLayout from "src/layouts/HomeLayout";
// import LoginLayout from "src/layouts/LoginLayout";
export const routes = [
  {
    exact: true,
    path: "/",
    layout: HomeLayout,
    component: lazy(() => import("src/views/auth/Main")),
  },

  // {
  //   exact: true,
  //   path: "/crowne-card",
  //   // guard:true,
  //   layout: HomeLayout,
  //   component: lazy(() => import("@/views/pages/Home/CrowneCard")),
  // },
  // {
  //   exact: true,
  //   path: "/letest-news",
  //   // guard:true,
  //   layout: HomeLayout,
  //   component: lazy(() => import("@/views/pages/Home/NewsSection")),
  // },
  // {
  //   exact: true,
  //   path: "/contact-us",
  //   layout: HomeLayout,
  //   component: lazy(() => import("@/views/pages/Home/ContactUs")),
  // },

  {
    exact: true,
    path: "/crowne-details",
    layout: HomeLayout,
    component: lazy(() => import("src/views/pages/Home/index")),
  },
  {
    exact: true,
    path: "/staking",
    // guard: true,
    layout: HomeLayout,
    component: lazy(() => import("src/views/pages/Staking")),
  },
  {
    exact: true,
    path: "/crowne-details1",
    // guard:true,
    layout: HomeLayout,
    component: lazy(() => import("src/views/pages/PoolDetails/CrowneDetails")),
  },
  {
    exact: true,
    path: "/spacepool-details",
    layout: HomeLayout,
    component: lazy(() => import("src/views/pages/SpacePoolDetails/index")),
  },
  {
    exact: true,
    path: "/staking-details",
    // guard:true,
    layout: HomeLayout,
    component: lazy(() => import("src/views/pages/PoolDetails/StakingDetails")),
  },
  {
    exact: true,
    path: "/afliate-details",
    // guard:true,
    layout: HomeLayout,
    component: lazy(() =>
      import("src/views/pages/PoolDetails/AfliatedDetails")
    ),
  },
  {
    exact: true,
    path: "/new-home",
    // guard:true,
    layout: HomeLayout,
    component: lazy(() => import("src/views/pages/NewHome")),
  },

  {
    exact: true,
    path: "/brackers",
    // guard:true,
    layout: HomeLayout,
    component: lazy(() => import("src/views/pages/Brackers")),
  },

  {
    exact: true,
    path: "/team",
    // guard:true,
    layout: HomeLayout,
    component: lazy(() => import("src/views/pages/Team")),
  },
  {
    exact: true,
    path: "/privacy-policy",
    // guard:true,
    layout: HomeLayout,
    component: lazy(() => import("src/views/pages/PrivacyPolicy")),
  },

  {
    exact: true,
    path: "/about",
    // guard:true,
    layout: HomeLayout,
    component: lazy(() => import("src/views/pages/About")),
  },
  {
    exact: true,
    path: "/service",
    // guard:true,
    layout: HomeLayout,
    component: lazy(() => import("src/views/pages/Service")),
  },

  {
    exact: true,
    path: "/404",
    component: lazy(() => import("src/views/errors/NotFound")),
  },
  {
    component: () => <Redirect to="/404" />,
  },
];
