// const url = "https://java-eldho.mobiloitte.org";
const url = "https://java.spacepoolglobal.com";

const ApiConfig = {
  // auth

  submitContactusRequest: `${url}/static/submit-support-ticket`,
};

export default ApiConfig;
