import React, { createContext, useState, useEffect } from "react";
import apiConfig from "src/config/APICongig";
import axios from "axios";

export const AuthContext = createContext();

const setSession = (accessToken) => {
  if (accessToken) {
    localStorage.setItem("token", accessToken);
  } else {
    localStorage.removeItem("token");
  }
};

function checkLogin() {
  const accessToken = window.localStorage.getItem("token");
  return accessToken ? true : false;
}

export default function AuthProvider(props) {
  const [isLogin, setIsLogin] = useState(checkLogin());
  const [userData] = useState({});

  const [profile, setProfile] = useState("");

  const getProfile = async () => {
    const response = await axios({
      method: "GET",
      url: apiConfig.getProfile,

      headers: {
        authorization: `Bearer ${window.localStorage.getItem("token")}`,
      },
    }).then(async (response) => {
      if (response.data.status === 200) {
        setProfile(response.data.data);
        window.localStorage.setItem("userId", response.data.data.userId);
        window.localStorage.setItem(
          "isSubscribe",
          response.data.data.isSubscribe
        );
        window.localStorage.setItem("firstName", response.data.data.firstName);
        window.localStorage.setItem("lastName", response.data.data.lastName);
      } else {
      }
    });
  };
  useEffect(() => {
    if (isLogin) {
      getProfile();
    }
  }, [isLogin]);

  let data = {
    userLoggedIn: isLogin,
    userData,
    userLogIn: (type, data) => {
      setSession(data);
      setIsLogin(type);
    },
    profile,
  };

  return (
    <AuthContext.Provider value={data}>{props.children}</AuthContext.Provider>
  );
}
